<template>
  <v-sheet outlined rounded class="pa-2 mb-2">
    <v-row class="rowSmall" align="center">
      <v-col cols="auto">
        <v-sheet width="530">
          <v-row class="rowSmall" align="center">
            <v-col cols="auto">
              <v-sheet rounded class="d-flex align-center fill-height" color="transparent" min-width="110">
                <v-row no-gutters class="d-inline-block">
                  <v-col cols="12">
                    <span class="font-weight-medium body-1">{{$tools.dateTimeFormat(visit.begin, 'HH:mm')}}</span>
                    <span class="caption px-1">-</span>
                    <span class="caption">{{$tools.dateTimeFormat(visit.end, 'HH:mm')}}</span>
                  </v-col>
                  <v-col cols="12" class="caption">
                    <chip v-for="v2 in statuses.filter(v1=>v1.id===visit.status)" :key="v2.id" small :color="v2.color" dark full-width class="px-2 text-center">
                      {{v2.name}}
                    </chip>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="auto">
              <v-sheet color="transparent" min-width="380">
                <div class="body-1">
                  {{visit.user.lastName}} {{visit.user.firstName}} {{visit.user.secondName}}
                </div>
                <div class="caption">
                  {{visit.user.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}
                  <div v-if="visit.user.userProfile.birth!==null" class="d-inline-block">
                    <dot class="mt-n1"/>
                    {{$tools.dateYearsSimple(visit.user.userProfile.birth, 'YYYY-MM-DD')}}
                  </div>
                  <dot class="mt-n1"/>
                  <span class="caption">Кабинет: {{visit.comPlace.comRoom.sid}}</span>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <div class="mt-2">
            <v-btn depressed small class="mr-2" @click="$emit('click')">
              Визит
            </v-btn>
            <v-btn depressed small class="mr-2" :href="'/panel/user/client/'+visit.user.id+'/'" target="visit_user_client">
              Профиль<v-icon dense class="ml-2" small>mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn v-if="$tools.isEmpty(visit.user.userProfile.medCardNumber)" depressed small class="mr-2" disabled>
              Мед.карта (не создана)
            </v-btn>
            <v-btn v-else depressed small class="mr-2" :href="$tools.isEmpty(visit.user.userProfile.medCardNumber) ? undefined : '/panel/user/client/'+visit.user.id+'/#media_medCard'" target="visit_user_client">
              Мед.карта {{$tools.isEmpty(visit.user.userProfile.medCardNumber) ? '(не создана)' : '#'+visit.user.userProfile.medCardNumber}}<v-icon dense class="ml-2" small>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
      <v-col v-if="!$tools.isEmpty(visit.user.userProfile.comment)" class="caption text-wrap">
        <v-sheet max-width="250" color="transparent">
          <div>Комментарии к клиенту:</div>
          <div>{{visit.user.userProfile.comment}}</div>
        </v-sheet>
      </v-col>
      <v-col v-if="!$tools.isEmpty(visit.comment)" class="caption text-wrap">
        <v-sheet max-width="250" color="transparent">
          <div>Комментарии к визиту:</div>
          <div>{{visit.comment}}</div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>
</template>
  
<script>
import Dot from "@/componentsV2/base/Dot.vue";
import Chip from "@/componentsV2/base/Chip.vue";
import Api from "@/Api";

export default {
  components: {Chip, Dot},
  props:['visit'],
  data:()=>({
    statuses:Api.model.Visit.statuses,
  })
}
</script>