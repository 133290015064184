<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab>Визиты</v-tab>
        <v-tab>Мед.планы</v-tab>
      </v-tabs>
    </portal>
    <desktop-visits v-if="tabSelected===0"/>
    <medplans v-if="tabSelected===1" currentUser/>
  </div>
</template>

<script>
import DesktopVisits from "@/views/Desktop/DesktopVisits.vue";
import Medplans from "@/views/MedPlan/MedPlans.vue";

export default {
  components: {DesktopVisits,Medplans},
  data:()=>({
    tabSelected:null,
  }),
}
</script>