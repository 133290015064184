<template>
  <div class="ma-4">
    <v-row class="rowSmall">
      <v-col>
        <v-sheet elevation="2" rounded :height="$tools.pageHeight()-12-48" class="overflow-hidden position-relative">
          <toolbar-view title="Запланировано (на сегодня)" :loading="visitsState.isLoading">
            <template v-slot:barActions>
              <v-sheet width="400" color="white">
                <user-autocomplete-single
                  @update:select="(v)=>{
                    userspecId=typeof v==='undefined' || v===null ? $store.state.user.id : v.id;
                    visitsLoad();
                    visitPlansLoad();
                  }"
                  class="mb-0"
                  :name="'Специалист '+(userspecId===$store.state.user.id ? '(по умолчанию текущий)' : '')"
                  _type="spec"
                />
              </v-sheet>
            </template>
          </toolbar-view>
          <v-tabs v-model="tabSelected" background-color="transparent">
            <v-tab>Обзор</v-tab>
            <v-tab>Будущие ({{visits.filter(v=>isFuture(v.begin)).length}})</v-tab>
            <v-tab>Прошедшие ({{visits.filter(v=>!isFuture(v.begin)).length}})</v-tab>
          </v-tabs>
          <div v-if="visits.length>0">
            <v-sheet
                v-if="tabSelected===0"
                class="position-relative overflow-auto px-4 pt-2"
                :height="$tools.pageHeight()-12-48-48-48"
                color="transparent"
            >
              <div v-if="visits.length===0" class="py-4 text-center">
                Не найдено
              </div>
              <div v-if="visits.filter(v=>v.status==='active').length>0" class="pb-2">
                <div class="mb-2 body-2 font-weight-medium">На приеме</div>
                <desktop-visit-item
                    v-for="v in visits.filter(v=>v.status==='active')" :key="v.id"
                    :visit="v"
                    @click="visitId=v.id"
                />
              </div>
              <div v-if="visits.filter(v=>v.status==='ready').length>0" class="pb-2">
                <div class="mb-2 body-2 font-weight-medium">Пришел (ожидает)</div>
                <desktop-visit-item
                    v-for="v in visits.filter(v=>v.status==='ready')" :key="v.id"
                    :visit="v"
                    @click="visitId=v.id"/>
              </div>
              <div v-if="getFutures().length>0" class="pb-2">
                <div class="mb-2 body-2 font-weight-medium">Далее по времени (через {{futureMinutes(getFutures()[0].begin)}} мин.)</div>
                <desktop-visit-item
                    v-for="v in getFutures()" :key="v.id"
                    :visit="v"
                    @click="visitId=v.id"
                />
              </div>
              <div v-if="getFutures(false).length>0" class="pb-2">
                <div class="mb-2 body-2 font-weight-medium">Подтвержденные (не пришедшие во время)</div>
                <desktop-visit-item
                    v-for="v in getFutures(false)" :key="v.id"
                    :visit="v"
                    @click="visitId=v.id"
                />
              </div>
            </v-sheet>
            <desktop-visits-table v-if="tabSelected===1" :visits="visits.filter(v=>isFuture(v.begin))" @click="(v)=>visitId=v.id"/>
            <desktop-visits-table v-if="tabSelected===2" :visits="visits.filter(v=>!isFuture(v.begin)).reverse()" @click="(v)=>visitId=v.id"/>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="auto">
        <v-sheet
            elevation="2"
            rounded
            class="position-relative overflow-auto"
            :height="$tools.pageHeight()-12-48"
            width="340"
        >
          <toolbar-view title="Лист живой очереди (на сегодня)" :loading="visitPlansState.isLoading"/>
          <v-sheet class="overflow-auto" :height="$tools.pageHeight()-12-48-48">
            <div v-if="visitPlansLive.length===0" class="py-4 text-center">
              Не найдено
            </div>
            <v-list v-else class="pa-0">
              <v-list-item
                  v-for="(visitPlan, k) in visitPlansLive" :key="'visitPlan'+k"
                  @click="$tools.openUrl('/panel/visit/?userId='+visitPlan.user.id+'&visitPlanId='+visitPlan.id+'&type=visitPlan')">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="font-weight-medium mb-1">
                      {{visitPlan.user.lastName=== null ? '': visitPlan.user.lastName}}
                      {{visitPlan.user.firstName=== null ? '': visitPlan.user.firstName}}
                      {{visitPlan.user.secondName=== null ? '': visitPlan.user.secondName}}
                    </div>
                    <div class="caption mb-1">К специалисту:</div>
                    <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                      <v-col cols="auto">
                        <v-avatar class="user" size="32">
                          <img :src="visitPlan.userSpec.user.imageUrl" v-if="visitPlan.userSpec.user.imageUrl" alt="">
                        </v-avatar>
                      </v-col>
                      <v-col class="pl-2">
                        <div class="font-weight-medium">
                          {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                          {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                          {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                        </div>
                        <div class="caption text-wrap">
                          {{visitPlan.userSpec.spec.name}}
                          <v-icon small>mdi-chevron-left</v-icon>
                          {{visitPlan.userSpec.comDiv.comBranch.name}}
                          <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                            <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                            {{visitPlan.userSpec.commentAdmin}}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                      <div class="caption">Комментарий:</div>
                      <div class="caption">{{visitPlan.comment}}</div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols="auto">
        <v-sheet
            elevation="2"
            rounded
            class="position-relative overflow-auto"
            :height="$tools.pageHeight()-12-48"
            width="340"
        >
          <toolbar-view title="Лист ожидания (на сегодня, ранее)" :loading="visitPlansState.isLoading"/>
          <v-sheet class="overflow-auto" :height="$tools.pageHeight()-12-48-48">
            <div v-if="visitPlansFuture.length===0" class="py-4 text-center">
              Не найдено
            </div>
            <v-list v-else class="pa-0">
              <v-list-item
                  v-for="(visitPlan, k) in visitPlansFuture" :key="'visitPlan'+k"
                  @click="$tools.openUrl('/panel/visit/?userId='+visitPlan.user.id+'&visitPlanId='+visitPlan.id+'&type=visitPlan')">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="font-weight-medium mb-1">
                      {{visitPlan.user.lastName=== null ? '': visitPlan.user.lastName}}
                      {{visitPlan.user.firstName=== null ? '': visitPlan.user.firstName}}
                      {{visitPlan.user.secondName=== null ? '': visitPlan.user.secondName}}
                    </div>
                    <div class="caption mb-1">С даты: {{$tools.date(visitPlan.begin)}}</div>
                    <div class="caption mb-1">К специалисту:</div>
                    <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                      <v-col cols="auto">
                        <v-avatar class="user" size="32">
                          <img :src="visitPlan.userSpec.user.imageUrl" v-if="visitPlan.userSpec.user.imageUrl" alt="">
                        </v-avatar>
                      </v-col>
                      <v-col class="pl-2">
                        <div class="font-weight-medium">
                          {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                          {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                          {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                        </div>
                        <div class="caption text-wrap">
                          {{visitPlan.userSpec.spec.name}}
                          <v-icon small>mdi-chevron-left</v-icon>
                          {{visitPlan.userSpec.comDiv.comBranch.name}}
                          <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                            <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                            {{visitPlan.userSpec.commentAdmin}}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                      <div class="caption">Комментарий:</div>
                      <div class="caption">{{visitPlan.comment}}</div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
    <visit :visitId.sync="visitId"/>
  </div>
</template>
  
<script>
import ToolbarView from "@/components/ToolbarView.vue";
import axios from "axios";
import Api from "@/Api";
import State from "@/plugins/state";
import moment from "moment/moment";
import DesktopVisitsTable from "@/views/Desktop/DesktopVisitsTable.vue";
import Visit from "@/views/Visit/Visit.vue";
import DesktopVisitItem from "@/views/Desktop/DesktopVisitItem.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";

export default {
  components: {UserAutocompleteSingle, DesktopVisitItem, Visit, DesktopVisitsTable, ToolbarView},
  data: () => ({
    userspecId:null,
    dateCurrent:null,

    tabSelected:0,
    visits:[],
    visitsState:new State(),
    visitId:null,

    visitPlansState:new State(),
    visitPlansLive:[],
    visitPlansFuture:[],

    visitCreateState:new State(),

    timer:null
  }),
  mounted() {
    this.userspecId = this.$store.state.user.id;
    this.dateCurrent = moment();
    this.visitsLoad();
    this.visitPlansLoad();
    this.timer = setInterval(()=>{
      this.$tools.pageVisibleCallback(()=> {
        this.visitsLoad();
        this.visitPlansLoad();
      }, ()=>{
        this.$store.commit('alertWarning', 'Данные могли устареть. Обновите страницу или подождите 15 сек.');
      })
    },15000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch:{
    visitId(){
      if(this.visitId===null)
        this.visitsLoad();
    }
  },
  methods: {
    visitsLoad(){
      this.visitsState.stateLoading();
      axios.get(Api.host+'/visit/?userspecId='+this.userspecId+'&begin='+this.dateCurrent.clone().format('YYYY-MM-DD')+'&end='+this.dateCurrent.clone().add(1, 'day').format('YYYY-MM-DD'))
          .then((r)=> {
            this.visits = r.data.filter(i=>i.user!==null);
            this.visitsState.stateSuccess();
          })
          .catch(()=>this.visitsState.stateError());
    },
    visitPlansLoad(){
      this.visitPlansState.stateLoading();
      axios.get(Api.host+'/visitPlan/?liveList=1&date='+this.dateCurrent.clone().format('YYYY-MM-DD')+'&page=1&onPage=100')
          .then((r) =>{
            this.visitPlansLive = r.data.data;
            this.visitPlansLive = this.visitPlansLive.filter(v=>moment(v.begin).format('YYYY-MM-DD')===this.dateCurrent.clone().format('YYYY-MM-DD'))
            this.visitPlansLive = this.visitPlansLive.filter(v=>v.userSpec.user.id===this.userspecId)

            axios.get(Api.host+'/visitPlan/?liveList=0&date='+this.dateCurrent.clone().format('YYYY-MM-DD')+'&page=1&onPage=100')
                .then((r) =>{
                  this.visitPlansFuture = r.data.data;
                  this.visitPlansFuture = this.visitPlansFuture.filter(v=>moment(v.begin).unix()<=this.dateCurrent.clone().unix() || moment(v.begin).format('YYYY-MM-DD')===this.dateCurrent.clone().format('YYYY-MM-DD'))
                  this.visitPlansFuture = this.visitPlansFuture.filter(v=>v.userSpec.user.id===this.userspecId)
                  this.visitPlansState.stateSuccess();
                })
                .catch(() =>{
                  this.visitPlansState.stateError();
                });
          })
          .catch(() =>{
            this.visitPlansState.stateError();
          });
    },
    isFuture(v){
      return moment(v, 'YYYY-MM-DD HH:mm:ss').unix()>=this.dateCurrent.clone().unix();
    },
    futureMinutes(v){
      return moment(v, 'YYYY-MM-DD HH:mm:ss').diff(this.dateCurrent.clone(), 'minutes')
    },
    getFutures(begin=true){
      return this.visits
          .filter(v=>v.status==='self' || v.status==='pre' || v.status==='confirmed')
          .filter(v=>{
            if(begin)
              return moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()>=this.dateCurrent.clone().unix();
            else
              return moment(v.begin, 'YYYY-MM-DD HH:mm:ss').unix()<this.dateCurrent.clone().unix();
          });
    }
  }
}
</script>