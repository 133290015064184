<template>
  <div>
    <div v-if="visits.length===0" class="py-4 text-center">
      Не найдено
    </div>
    <v-simple-table fixed-header v-else :height="$tools.pageHeight()-12-48-48-48" dense class="desktopTableVisits">
      <thead>
      <tr>
        <th class="pl-4">Время</th>
        <th>Клиент</th>
        <th>Мед.карта</th>
        <th>Комментарий</th>
        <th>Кабинет</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="v in visits" :key="v.id" @click="$emit('click', v)">
        <td class="pl-4">
          <v-row no-gutters class="d-inline-block full-width">
            <v-col cols="12">
              <span class="font-weight-medium body-1">{{$tools.dateTimeFormat(v.begin, 'HH:mm')}}</span>
              <span class="caption px-1">-</span>
              <span class="caption">{{$tools.dateTimeFormat(v.end, 'HH:mm')}}</span>
            </v-col>
            <v-col cols="12" class="caption pb-1">
              <chip v-for="v2 in statuses.filter(v1=>v1.id===v.status)" :key="v2.id" small :color="v2.color" dark full-width class="px-2 text-center">
                {{v2.name}}
              </chip>
            </v-col>
          </v-row>
        </td>
        <td class="py-1">
          <div class="font-weight-medium body-2">{{v.user.lastName}} {{v.user.firstName}} {{v.user.secondName}}</div>
          <div class="caption">
            {{v.user.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}
            <span v-if="v.user.userProfile.birth!==null">
              <dot class="mt-0"/>
              {{$tools.dateYearsSimple(v.user.userProfile.birth, 'YYYY-MM-DD')}}
            </span>
          </div>
        </td>
        <td>
          {{v.user.userProfile.medCardNumber}}
        </td>
        <td class="caption text-wrap">
          <v-sheet max-width="500" color="transparent">
            <div v-if="!$tools.isEmpty(v.user.userProfile.comment)">Клиент: {{v.user.userProfile.comment}}</div>
            <div v-if="!$tools.isEmpty(v.comment)">Визит: {{v.comment}}</div>
          </v-sheet>
        </td>
        <td>
          {{v.comPlace.comRoom.sid}}
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
  
<script>
import Dot from "@/componentsV2/base/Dot.vue";
import Api from "@/Api";
import Chip from "@/componentsV2/base/Chip.vue";

export default {
  components: {Chip, Dot},
  props:['visits'],
  data:()=>({
    statuses:Api.model.Visit.statuses,
  })
}
</script>

<style scoped>
.desktopTableVisits tr th:nth-child(1),
.desktopTableVisits tr td:nth-child(1),
.desktopTableVisits tr th:nth-child(2),
.desktopTableVisits tr td:nth-child(2),
.desktopTableVisits tr th:nth-child(3),
.desktopTableVisits tr td:nth-child(3){
  width: initial !important;
}
.desktopTableVisits tr th:nth-child(4),
.desktopTableVisits tr td:nth-child(4) {
  width: 100% !important;
}
.desktopTableVisits tr th:nth-child(5),
.desktopTableVisits tr td:nth-child(5) {
  width: initial !important;
}
</style>